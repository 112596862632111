import axios from 'axios';
import { useIndexCR } from '../Factories/useIndexCR';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  IAuthenticationMethod,
  IAuthenticationMethodCreateInput,
} from '../Types/Resources/IAuthenticationMethod';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IAuthenticationMethodProps {
  portal?: UUID;
}

export async function getAuthenticationMethods(
  { portal }: IAuthenticationMethodProps,
  domainName: string
) {
  const response = await axios.get(
    `${process.env.API_ENDPOINT}/v1/portals/${portal}/authentication_methods`,
    {
      headers: {
        'X-Portal-Domain': process.env.PORTAL_DOMAIN || domainName,
        'X-Feature-Next': '1',
      },
    }
  );
  const document = new DataDocument<IAuthenticationMethod[]>(response);
  return [document.data, document] as const;
}

export function useAuthenticationMethods(
  { portal, ...queryParameters }: IAuthenticationMethodProps = {},
  settings?: IUseQueryOptions<
    DataDocument<IAuthenticationMethod[]>,
    ErrorDocument
  >
) {
  const {
    parameters: { portal: defaultPortal },
  } = useApi();
  const request = new ApiRequest({
    enabled: !!(portal || defaultPortal),
    baseName: 'v1.portals.authentication_methods',
    baseUri: `/v1/portals/${portal || defaultPortal}/authentication_methods`,
    invalidate: ['authentication_method', 'authentication_methods'],
    queryParameters,
  });
  return useIndexCR<IAuthenticationMethod, IAuthenticationMethodCreateInput>(
    request,
    settings
  );
}
