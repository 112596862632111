import React, { useCallback } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ChevronLeft } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import styles from './BackButton.module.css';

const cx = classNames.bind(styles);

type TProps = {
  returnTo?: string;
};

export const BackButton = ({ returnTo }: TProps) => {
  const [t] = useTranslator();

  const router = useRouter();

  const handleBack = useCallback(() => {
    if (returnTo) {
      router.push(returnTo);
    } else {
      router.back();
    }
  }, [router, returnTo]);

  return (
    <Button
      quiet
      link
      type="button"
      title={t('go_back')}
      onClick={handleBack}
      className={cx(styles.base)}
    >
      <ChevronLeft style={{ fontSize: '3rem' }} />
      <span>{t('go_back')}</span>
    </Button>
  );
};
