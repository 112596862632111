import { useEffect, useState } from 'react';
import { useDebounce } from '@brainstud/universal-components/Hooks/useDebounce';
import { TLengthOptions } from 'Utils/validators';

export type TValidator = (
  value: string,
  options?: TLengthOptions
) => string | null | boolean;

/**
 * A hook that runs the validators provided on the value.
 *
 * You can provide one or more validation functions to this hook. All functions will be run
 * and when they match, they will return the errors of the violations.
 *
 * @param validators Small functions that evaluate the value
 * @param value The value that is evaluated
 * @param options You can pass option to the validator, for now only min and max is working
 * @param debounce Time between value change and validation
 * @returns Undefined when input is correct, or string[] when validation rules are violated.
 */
export function useValidator(
  validators?: TValidator | TValidator[],
  value?: string,
  options?: TLengthOptions,
  debounce: number = 500
) {
  const [errors, setErrors] = useState<string[]>();
  const debouncedValue = useDebounce(value, debounce);

  useEffect(() => {
    if (debouncedValue !== undefined && validators) {
      const validations = [validators]
        .flat()
        .reduce<
          string[]
        >((outcomes, validator) => [...([validator(debouncedValue, options)].filter(Boolean) as string[]), ...outcomes], []);
      if (validations.length > 0) {
        setErrors(validations);
      } else {
        setErrors(undefined);
      }
    }
  }, [debouncedValue, validators]);

  return errors;
}
