import React, { PropsWithChildren } from 'react';
import { useFormField } from '@brainstud/universal-components/Components/Form/useFormField';
import classNames from 'classnames/bind';
import { Indicator } from '../../Loaders/Indicator';
import { TextareaProps } from './TextareaProps';
import styles from './Textarea.module.css';

const cx = classNames.bind(styles);

/**
 * Textarea.
 *
 * Renders a styled textarea based on the configured theme. It is connected to the FormContext if it is
 * present. Finally, it has some basic validation rules support.
 */
export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  PropsWithChildren<TextareaProps>
>(
  (
    {
      id,
      name,
      value,
      defaultValue,
      rules,
      valid: defaultValid,
      label,
      tabIndex,
      readOnly = false,
      disabled,
      autoComplete = 'off',
      onBlur,
      onFocus,
      onChange,
      onInput,
      onInvalid,
      onReset,
      onSubmit,
      onContextMenu,
      className,
      style,
      hidden = false,
      placeholder,
      size,
    },
    ref
  ) => {
    const identifier = typeof id === 'number' ? `${name}_${id}` : id || name;
    const {
      value: controlledValue,
      setValue,
      field,
      valid,
      locked,
    } = useFormField({
      id: name ? identifier : undefined,
      name,
      value,
      defaultValue,
      rules,
    });

    const { messages } = field || {};
    const validity = valid === undefined || !rules ? defaultValid : valid;
    const hasErrors = messages && messages.length > 0;

    return (
      <div
        className={cx(
          styles.base,
          {
            'has-errors': hasErrors,
            'is-hidden': hidden,
            'is-required': rules?.includes('required'),
          },
          className
        )}
        style={{ ...style, width: size ? `${size}%` : undefined }}
      >
        {label && (
          <label
            htmlFor={identifier}
            id={`${identifier}_label`}
            className={cx('label')}
          >
            {label}
          </label>
        )}
        <div className={cx(styles['textarea-wrapper'])}>
          <textarea
            name={name}
            id={identifier}
            className={cx('textarea', {
              'has-text': controlledValue,
            })}
            placeholder={placeholder}
            ref={ref}
            tabIndex={tabIndex}
            readOnly={readOnly}
            disabled={locked || disabled}
            autoComplete={autoComplete}
            onBlur={onBlur}
            value={
              ['string', 'number'].includes(typeof controlledValue)
                ? String(controlledValue)
                : ''
            }
            aria-label={!label && !!placeholder ? placeholder : undefined}
            aria-labelledby={label ? `${id}_label` : undefined}
            onFocus={onFocus}
            onChange={(event) => {
              setValue(event.target.value);
              onChange?.(event);
            }}
            onInput={onInput}
            onInvalid={onInvalid}
            onReset={onReset}
            onSubmit={onSubmit}
            onContextMenu={onContextMenu}
          />
          <Indicator
            success={validity === true}
            error={validity === false}
            className={cx(styles.indicator, {
              'is-valid': validity === true,
              'is-invalid': validity === false,
            })}
          />
        </div>
        <div
          role="alert"
          className={cx(styles.messages, { 'has-messages': hasErrors })}
        >
          {hasErrors && messages![0]}
        </div>
      </div>
    );
  }
);
