import React from 'react';
import { Product } from '@brainstud/academy-api/Types/Resources/Product';
import { Badge } from '@brainstud/ui/Static/Badge';
import { CheckCircle } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './ProductCard.module.css';

const cx = classNames.bind(styles);

interface ISubscription {
  subscription: Product;
  selected: boolean;
  onSelect: (a: string | null) => void;
  disabled?: boolean;
}

export const ProductCard = ({
  subscription,
  selected,
  onSelect,
  disabled = false,
}: ISubscription) => {
  const [t] = useTranslator();

  const featureList = subscription.metadata?.informationLines?.features;
  const productIcon = subscription.metadata?.media?.icon;
  const isPopular = subscription.metadata?.labels?.popular;

  return (
    <div
      className={cx(styles.base, { selected, disabled })}
      onClick={() => onSelect(subscription.id)}
      role="presentation"
    >
      {/* Most popular */}
      <div className={cx(styles['badge-wrapper'], { visible: isPopular })}>
        <Badge className={cx(styles.badge)}>
          {t('checkout.product_cart.most_popular')}
        </Badge>
      </div>
      {/* Title */}
      <div className={styles.title}>
        <h3>{subscription.name.replace('abonnement', '')}</h3>
      </div>
      {/* Icons */}
      {productIcon && (
        <div className={styles.icon}>
          <img src={productIcon} alt="icon" />
        </div>
      )}
      {/* Price */}
      {subscription.priceAfterVat && (
        <div className={cx(styles['price-wrapper'])}>
          {!subscription.discount && (
            <strong
              className={cx(styles.discount, {
                visible: subscription.discount,
              })}
            >
              &euro;
              {subscription.discount}
            </strong>
          )}
          <div className={cx(styles['current-price'])}>
            <h3 className={cx(styles.euro)}>&euro;</h3>
            <span className={cx(styles.price)}>
              {subscription.priceAfterVat?.toFixed(2).replace(/\./, ',')}
            </span>
          </div>
        </div>
      )}
      {/* Product features */}
      {featureList && (
        <div className={cx(styles.features)}>
          {subscription.metadata?.informationLines?.features?.map((feature) => (
            <div key={feature} className={cx(styles.feature)}>
              <CheckCircle />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
