import React, { PropsWithChildren, useMemo } from 'react';
import { useAuthenticationMethods } from '@brainstud/academy-api/Hooks/useAuthenticationMethods';
import { Panel } from '@brainstud/ui/Static/Panel';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useHeadProvider } from 'Providers/HeadProvider/useHeadProvider';
import { useThemeProvider } from 'Providers/ThemeProvider';
import { ReactComponent as LogoSVG } from '../../Modules/account-panel/Assets/images/logo.svg';
import { BackButton } from '../../Modules/account-panel/Components';
import { Main } from '../Main';
import styles from './AuthLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
};

/**
 * AuthLayout.
 *
 * Shows the basic layout of an account-panel page.
 */
export const AuthLayout = ({ title, children }: PropsWithChildren<Props>) => {
  useHeadProvider(title || 'titles.auth.main');
  const { assets } = useThemeProvider();
  const router = useRouter();
  const logo = assets?.logo;
  const [{ data }] = useAuthenticationMethods();

  const returnTo = useMemo(() => {
    switch (router.pathname) {
      case '/auth':
        return '/';
      case data.length === 1 ? '/' : '/auth/login':
        return '/auth';
      case '/auth/login/register':
        return '/auth/login';
      case '/auth/login/forgot-password':
        return '/auth/login';
      default:
        return '/';
    }
  }, [data.length, router.pathname]);

  return (
    <div className={cx(styles.base)}>
      <Panel className={cx(styles.panel)}>
        {!(router.pathname === '/auth/login/reset-password') && (
          <BackButton returnTo={returnTo} />
        )}
        <header>
          {logo ? (
            <img className={cx(styles.logo)} src={logo} alt="" />
          ) : (
            <LogoSVG className={cx(styles.logo)} />
          )}
        </header>
        <Main className={cx(styles.main)} pad={false}>
          {children}
        </Main>
      </Panel>
    </div>
  );
};
