import React, { ReactNode, Suspense } from 'react';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import styles from './Main.module.css';

const cx = classNames.bind(styles);

type TProps = {
  /** Adds a little gap between the top and bottom of the page main content, default = true */
  pad?: boolean;
  /** Sets the width to full width for the container component */
  wide?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children: ReactNode;
};

/**
 * Main.
 *
 * A component around the main content of the page. Has loading states and error states build in.
 */
export const Main = ({
  pad = true,
  style,
  wide,
  loading,
  className,
  children,
}: TProps) => (
  <Container
    as="main"
    pad={pad}
    wide={wide}
    style={style}
    className={cx(styles.base, className)}
  >
    <ErrorBoundary container>
      {loading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>{children}</Suspense>
      )}
    </ErrorBoundary>
  </Container>
);
