import { useCallback, useRef, useState } from 'react';
import { usePrepaidCodeActivation } from '@brainstud/academy-api/Hooks/usePrepaidCodeActivation';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Callout } from '@brainstud/ui/Static/Callout';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import styles from './PrepaidActivationForm.module.css';

type Props = {
  className?: string;
  onChange?: (value?: string) => void;
};

const cx = classNames.bind(styles);

export const PrepaidActivationForm = ({ className, onChange }: Props) => {
  const [t] = useTranslator();
  const redeemPrepaidCode = usePrepaidCodeActivation();
  const router = useRouter();
  const { push: goToPage } = router;

  const prepaidInputField = useRef<HTMLInputElement>(null);

  const [prepaidCode, setPrepaidCode] = useState<string>('');
  const [errors, setErrors] = useState<Array<{ code: string }>>([]);

  const checkPrepaidCode = useCallback(() => {
    redeemPrepaidCode.mutate(
      {
        prepaid_code: prepaidCode,
      },
      {
        onSuccess: () => {
          goToPage('/account/subscriptions/confirmation');
        },
        onError: (errorDocument) => {
          setErrors(errorDocument.errors || [{ code: t('error') }]);
          prepaidInputField.current?.focus();
        },
      }
    );
  }, [prepaidCode, redeemPrepaidCode, goToPage, t]);

  const handlePrepaidCode = useCallback(
    (value: string) => {
      setPrepaidCode(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <div className={cx(styles.prepaid, className)}>
      <p>
        <strong>{t('checkout.fill_in_prepaid_code')}</strong>
      </p>
      <div className={styles.inputRow}>
        <Input
          type="text"
          ref={prepaidInputField}
          disabled={redeemPrepaidCode.isPending}
          className={styles.prepaidcode}
          placeholder={t('checkout.prepaid_code')}
          onChange={(e) => handlePrepaidCode(e.target.value)}
        />
        <Button
          quiet={prepaidCode === ''}
          disabled={!prepaidCode.length}
          onClick={checkPrepaidCode}
          className={cx({ submit: prepaidCode === '' })}
        >
          {t('checkout.redeem')}
        </Button>
      </div>
      {errors.length > 0 &&
        errors.map((error) => (
          <Callout
            key={error.code}
            error
            margin="1rem 0"
            style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
          >
            <p>
              {error.code
                ? t(
                    `components.prepaid_form.errors.${error.code}`,
                    undefined,
                    error.code
                  )
                : t('error')}
            </p>
          </Callout>
        ))}
    </div>
  );
};
